<template>
  <div>
    <validation-observer ref="simpleRules">
     
      <b-card title="Product Status">

        <b-row>
          <b-col cols="12">
          <b-row>
            <b-col md="1">
              <div class="d-flex justify-content-center align-items-center">
                <b-img fluid :src="recData.cover"></b-img>
              </div>
            </b-col>
            <b-col class="my-auto" md="9">
              <h4>
                <span style="color:#7367F0;"
                  >name :</span
                >
                {{ name }}
              </h4>
              <br />
              <h4>
                <span style="color:#7367F0;"
                  >SKU رمز :
                </span>
                {{ recData.sku }}
              </h4>
              <br />
            </b-col>
          </b-row>
        </b-col>
          <b-col md="6">
            <b-row>
              <b-col
                md="4"
                class="d-flex justify-content-center align-items-center"
              >
                <!-- Avability -->
                <b-card-text v-if="productStatus === 'confirmed'">
                  <span style="color: #0d6efd">Status:</span>
                  -
                  <b-badge variant="success">{{ productStatus }}</b-badge>
                </b-card-text>
                <b-card-text v-if="productStatus === 'enabled'">
                  <span style="color: #0d6efd">Status:</span>
                  -
                  <b-badge variant="success">{{ productStatus }}</b-badge>
                </b-card-text>
                <b-card-text v-if="productStatus === 'disabled'">
                  <span style="color: #0d6efd">Status:</span>
                  -
                  <b-badge variant="danger">{{ productStatus }}</b-badge>
                </b-card-text>
                <b-card-text v-if="productStatus === 'review'">
                  <span style="color: #0d6efd">Status:</span>
                  -
                  <b-badge variant="warning">{{ productStatus }}</b-badge>
                </b-card-text>
                <b-card-text v-if="productStatus === 'rejected'">
                  <span style="color: #0d6efd">Status:</span>
                  -
                  <b-badge variant="dark">{{ productStatus }}</b-badge>
                </b-card-text>
              </b-col>
              <b-col md="8">
                <b-button block v-b-modal.modal-status variant="info">
                  Update Status
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-modal
            style="height: 700px !important"
            id="modal-status"
            cancel-variant="outline-secondary"
            @ok="updateStatus"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Update Status"
          >
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <b-form-group
                label="Status"
                label-for="status"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="status"
                  v-model="statusSelect"
                  :state="statusSelect === null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <b-form-checkbox
              id="checkbox-1"
              v-model="disabled"
              v-show="
                statusSelect.value == 'disabled' ||
                statusSelect.value == 'enabled'
              "
              name="checkbox-1"
              value="1"
              unchecked-value="0"
            >
              Force Disabled
            </b-form-checkbox>
          </b-modal>
        </b-row>
      </b-card>
    </validation-observer>

    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values , indexValue) in errors_back" :key="indexValue">
          <li v-for="(value , index) in values"  :key="index">{{ value }}</li>
        </ul> 
      </div>
    </b-alert>
  </div>
</template>

<script>
import { required } from '@validations'
export default {
  
  props: {
    cover: '',
  },
 
  watch: {
    categorySelect: function () {
      this.getItems()
    },
    brandSelect: function () {
      this.getItems()
    },
    statusSelect: function () {
      this.disabled = '1'
    },
  },
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      // cover: '',
      setupCover: '',
      disabled: '1',
      productStatus: null,
      statusSelect: {
        text: '',
        value: '',
      },
      statusOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },

        {
          value: 'disabled',
          text: 'Disabled',
        },
        {
          value: 'blocked',
          text: 'Blocked',
        },
        {
          value: 'rejected',
          text: 'Rejected',
        },
      ],
      categorySelect: {
        text: '',
        value: '',
      },
      categoryOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      brandSelect: {
        text: '',
        value: '',
      },
      brandOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      activeSelect: {
        text: '',
        value: '',
      },
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '1',
          text: 'True',
        },
        {
          value: '0',
          text: 'False',
        },
      ],
      itemSelect: {
        value: '',
        text: '',
      },
      itemOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],

      vendorOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      vendorSelect: {
        value: '',
        text: '',
      },
      recData: {},
      itemSe: false,
      itemData: {
        active: '',
        category_id: '',
        brand_id: '',
        sku: '',
        price: '',
        quantity: '',
        vendor_id: '',
        item_id: '',
      },
      name:"",
      required,
      authPermissions : [],
    }
  },
  created() {
    this.id = this.$route.params.id
    this.fetchData()
    this.authPermissions = this.$store.state.authPermissions

    if (this.authPermissions.includes('enabled-product')) {
      this.statusOptions.push({
        value: 'enabled',
        text: 'Enabled',
      })
    }
    if (this.authPermissions.includes('confirmed-product')) {
      this.statusOptions.push({
        value: 'confirmed',
        text: 'Confirmed',
      })
    }
    axios
      .get('vendors')
      .then((result) => {
        const data = result.data.data
        for (let ind in data) {
          this.vendorOptions.push({
            text: data[ind].firstname + ' ' + data[ind].lastname,
            value: data[ind].id.toString(),
          })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    axios
      .get('products/create')
      .then((result) => {
        const data = result.data.data
        if (data.categories.length > 0) {
          for (let ind in data.categories) {
            if (data.categories[ind].translation != null) {
              this.categoryOptions.push({
                text: data.categories[ind].translation.name,
                value: data.categories[ind].id.toString(),
              })
            }
          }
        }
        if (data.brands.length > 0) {
          for (let ind in data.brands) {
            if (data.brands[ind].translation != null) {
              this.brandOptions.push({
                text: data.brands[ind].translation.name,
                value: data.brands[ind].id.toString(),
              })
            }
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    fetchData() {
      axios
        .get('products/' + this.id)
        .then((result) => {
          const data = result.data.data
          this.recData = data
          this.name = this.recData.translation[0].name
          if ((data.active = true)) {
            this.activeSelect.text = 'True'
            this.activeSelect.value = '1'
          } else {
            this.activeSelect.text = 'False'
            this.activeSelect.value = '0'
          }
          if (data.category.translation.length > 0) {
            this.categorySelect.value = data.category.id.toString()
            this.categorySelect.text = data.category.translation[0].name
          }
          if (data.brand.translation.length > 0) {
            this.brandSelect.value = data.brand.id.toString()
            this.brandSelect.text = data.brand.translation[0].name
          }
          if (data.phoneGuide.item_translations.length > 0) {
            this.itemSelect.value = data.phoneGuide.id.toString()
            this.itemSelect.text = data.phoneGuide.item_translations[0].name
          }
          this.productStatus = data.status
          //console.log(data.status)
          if (data.status == 'enabled') {
            this.statusSelect.text = 'Enabled'
            this.statusSelect.value = 'enabled'
          } else if (data.status == 'disabled') {
            this.statusSelect.text = 'Disabled'
            this.statusSelect.value = 'disabled'
          } else if (data.status == 'review') {
            this.statusSelect.text = 'Review'
            this.statusSelect.value = 'review'
          } else {
            this.statusSelect.text = 'Rejected'
            this.statusSelect.value = 'rejected'
          }
          this.vendorSelect.value = data.vendor.id.toString()
          this.vendorSelect.text =
            data.vendor.firstname + ' ' + data.vendor.lastname
          this.itemData.price = data.price
          this.itemData.sku = data.sku
          this.itemData.quantity = data.quantity
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    updateStatus() {
      axios
        .get(
          'products/' +
            this.id +
            '/change-status/' +
            this.statusSelect.value +
            '/' +
            this.disabled
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.itemData.active = this.activeSelect.value
          this.itemData.category_id = this.categorySelect.value
          this.itemData.brand_id = this.brandSelect.value
          this.itemData.item_id = this.itemSelect.value
          this.itemData.vendor_id = this.vendorSelect.value
          axios
            .put('products/' + this.id, this.itemData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    getItems() {
      if (this.categorySelect != null && this.brandSelect != null) {
        axios
          .get(
            'items/' +
              this.categorySelect.value +
              '/' +
              this.brandSelect.value +
              '/get-items'
          )
          .then((result) => {
            const data = result.data.data
            this.itemSe = false
            if (data.length > 0) {
              for (let ind in data) {
                if (data[ind].item_translations != null) {
                  this.itemOptions.push({
                    text: data[ind].item_translations.name,
                    value: data[ind].id.toString(),
                  })
                }
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      }
    },
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.itemData.cover = e.target.result
      }
      reader.readAsDataURL(file)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
</style>
